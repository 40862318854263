import { createSlice } from "@reduxjs/toolkit";

export const rolesSlice = createSlice({
  name: "roles",
  initialState: {
    roles: [],
    selectedRole: "",
    roleCategories: [],
  },
  reducers: {
    setSelectedRole: (state, action) => {
      state.selectedRole = action.payload;
    },
    setRoles: (state, action) => {
      state.roles = action.payload;
    },
    clearSelectedRole: (state) => {
      state.selectedRole = "";
    },
    clearRoles: (state) => {
      state.roles = [];
    },
    setRoleCategories: (state, action) => {
      state.roleCategories = action.payload;
    },
  },
});

export const {
  setSelectedRole,
  setRoles,
  clearSelectedRole,
  clearRoles,
  setRoleCategories,
} = rolesSlice.actions;

export default rolesSlice.reducer;
