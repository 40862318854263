import { createSlice } from "@reduxjs/toolkit";

export const servicesSlice = createSlice({
  name: "services",
  initialState: {
    services: [],
    subServices: [],
    subServicesItems: {},
    chosenService: null,
    chosenSubServices: [],
    chosenSubServicesItems: {},
  },
  reducers: {
    setServices: (state, action) => {
      state.services = action.payload;
    },
    clearServices: (state) => {
      state.services = [];
    },
    setSubServices: (state, action) => {
      state.subServices = action.payload;
    },
    clearSubServices: (state) => {
      state.subServices = [];
    },
    setSubServicesItems: (state, action) => {
      state.subServicesItems = action.payload;
    },
    clearSubServicesItems: (state) => {
      state.subServicesItems = {};
    },
    setChosenService: (state, action) => {
      state.chosenService = action.payload;
    },
    clearChosenService: (state) => {
      state.chosenService = null;
    },
    setChosenSubServices: (state, action) => {
      state.chosenSubServices = action.payload;
    },
    addChosenSubServices: (state, action) => {
      state.chosenSubServices = [...state.chosenSubServices, action.payload];
    },
    removeChosenSubServices: (state, action) => {
      state.chosenSubServices = state.chosenSubServices.filter(
        (subService) => subService.id !== action.payload.id
      );
    },
    clearChosenSubServices: (state) => {
      state.chosenSubServices = [];
    },
    setChosenSubServicesItems: (state, action) => {
      const { subserviceKey, items } = action.payload;
      state.chosenSubServicesItems[subserviceKey] = items;
    },
    addChosenSubServicesItems: (state, action) => {
      const { subserviceKey, item } = action.payload;
      if (!state.chosenSubServicesItems[subserviceKey]) {
        state.chosenSubServicesItems[subserviceKey] = [];
      }
      state.chosenSubServicesItems[subserviceKey].push(item);
    },
    removeChosenSubServicesItems: (state, action) => {
      const { subserviceKey, itemId } = action.payload;
      if (state.chosenSubServicesItems[subserviceKey]) {
        state.chosenSubServicesItems[subserviceKey] = state.chosenSubServicesItems[subserviceKey].filter(
          (item) => item.id !== itemId
        );
      }
    },
    clearChosenSubServicesItems: (state, action) => {
      if (action.payload && action.payload.subserviceKey) {
        const { subserviceKey } = action.payload;
        delete state.chosenSubServicesItems[subserviceKey];
      } else {
        state.chosenSubServicesItems = {};
      }
    },
  },
});

export const {
  setServices,
  clearServices,
  setSubServices,
  clearSubServices,
  setSubServicesItems,
  clearSubServicesItems,
  setChosenService,
  clearChosenService,
  setChosenSubServices,
  addChosenSubServices,
  removeChosenSubServices,
  clearChosenSubServices,
  setChosenSubServicesItems,
  addChosenSubServicesItems,
  removeChosenSubServicesItems,
  clearChosenSubServicesItems,
} = servicesSlice.actions;

export default servicesSlice.reducer;
