import { createSlice } from "@reduxjs/toolkit";

export const companiesSlice = createSlice({
  name: "companies",
  initialState: {
    companies: [],
    selectedCompany: null,
    nextPage: null,
    prevPage: null,
    count: null,
  },
  reducers: {
    setCompaniesResult: (state, action) => {
      state.companies = action.payload.data;
      state.next = action.payload.pagination.next;
      state.previous = action.payload.pagination.previus;
      state.count = action.payload.pagination.count;
    },
    setCompanies: (state, action) => {
      state.companies = action.payload.data;
    },
    clearCompanies: (state) => {
      state.companies = [];
    },
    setSelectedCompany: (state, action) => {
      state.selectedCompany = action.payload;
    },
    clearSelectedCompany: (state) => {
      state.selectedCompany = null;
    },
    setNextPage: (state, action) => {
      state.next = action.payload;
    },
    setPrevPage: (state, action) => {
      state.previus = action.payload;
    },
    setCount: (state, action) => {
      state.count = action.payload;
    },
  },
});

export const {
  setCompaniesResult,
  setCompanies,
  clearCompanies,
  setSelectedCompany,
  clearSelectedCompany,
} = companiesSlice.actions;

export default companiesSlice.reducer;
