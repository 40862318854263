import { createSlice } from "@reduxjs/toolkit";

export const masterplanSlice = createSlice({
  name: "masterplan",
  initialState: {
    isLoading: true,
    rows: [],
  },

  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setDGTreeRows: (state, action) => {
      state.rows = action.payload;
    },
    clearAll(state) {
      state.isLoading = true;
      state.rows = [];
    }
  },
});

export const {
  setIsLoading,
  setDGTreeRows,
  clearAll
} = masterplanSlice.actions;

export default masterplanSlice.reducer;
