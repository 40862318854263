import { createSlice } from "@reduxjs/toolkit";

export const operatorsSlice = createSlice({
  name: "operators",
  initialState: {
    operators: [],
    selectedOperators: [],
    invitedOperators: [],
  },
  reducers: {
    setOperators: (state, action) => {
      state.operators = action.payload;
    },
    clearOperators: (state) => {
      state.operators = [];
    },
    setSelectedOperators: (state, action) => {
      state.selectedOperators = action.payload;
    },
    addSelectedOperator: (state, action) => {
      state.selectedOperators = [...state.selectedOperators, action.payload];
    },
    removeSelectedOperator: (state, action) => {
      state.selectedOperators = state.selectedOperators.filter(
        (operator) => operator.id !== action.payload
      );
    },
    clearSelectedOperators: (state) => {
      state.selectedOperators = [];
    },
    setInvitedOperators: (state, action) => {
      state.invitedOperators = action.payload;
    },
    clearInvitedOperators: (state) => {
      state.invitedOperators = [];
    },
  },
});

export const {
  setOperators,
  clearOperators,
  setSelectedOperators,
  addSelectedOperator,
  removeSelectedOperator,
  clearSelectedOperators,
  setInvitedOperators,
  clearInvitedOperators,
} = operatorsSlice.actions;

export default operatorsSlice.reducer;
