import { createSlice } from "@reduxjs/toolkit";

export const teamsSlice = createSlice({
  name: "teams",
  initialState: {
    teams: [],
    selectedTeam: "",
    selectedTeamOperators: [],
    roles: [],
    teamOperatorRoles: [],
  },
  reducers: {
    setTeams: (state, action) => {
      state.teams = action.payload;
    },
    clearTeams: (state) => {
      state.teams = [];
    },
    setSelectedTeam: (state, action) => {
      state.selectedTeam = action.payload;
    },
    clearSelectedTeam: (state) => {
      state.selectedTeam = "";
    },
    setSelectedTeamOperators: (state, action) => {
      state.selectedTeamOperators = action.payload;
    },
    clearSelectedTeamOperators: (state) => {
      state.selectedTeamOperators = [];
    },
    setRoles: (state, action) => {
      state.roles = action.payload;
    },
    clearRoles: (state) => {
      state.roles = [];
    },
    setTeamOperatorRoles: (state, action) => {
      state.teamOperatorRoles = action.payload;
    },
    clearTeamOperatorRoles: (state) => {
      state.teamOperatorRoles = [];
    },
  },
});

export const {
  setTeams,
  clearTeams,
  setSelectedTeam,
  clearSelectedTeam,
  setSelectedTeamOperators,
  clearSelectedTeamOperators,
  setRoles,
  clearRoles,
  setTeamOperatorRoles,
  clearTeamOperatorRoles,
} = teamsSlice.actions;

export default teamsSlice.reducer;
