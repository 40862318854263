import { createSlice } from "@reduxjs/toolkit";



export const profileSlice = createSlice({
  name: "profile",
  initialState: {
    user: null,
    mediaId: null,
    userTeamOperatorsRoles: null,
    userOperatorsInstances: [],
    selectedOperatorInstance: null,
    selectedUserRole: null,
    otherUserData: null
  },
  reducers: {
    setUserProfile: (state, action) => {
      state.user = action.payload.operator;
    },
    clearUserProfile: (state) => {
      state.user = null;
    },
    setMediaId: (state, action) => {
      state.mediaId = action.payload;
    },
    clearMediaId: (state) => {
      state.mediaId = null;
    },
    setUserTeamOperatorsRoles: (state, action) => {
      state.userTeamOperatorsRoles = action.payload.team_operators_roles;
    },
    clearUserTeamOperatorsRoles: (state) => {
      state.userTeamOperatorsRoles = null;
    },
    setUserOperatorsInstances: (state, action) => {
      state.userOperatorsInstances = action.payload;
    },
    clearUserOperatorsInstances: (state) => {
      state.userOperatorsInstances = [];
    },
    setSelectedOperatorInstance: (state, action) => {
      state.selectedOperatorInstance = action.payload;
    },
    clearSelectedOperatorInstance: (state) => {
      state.selectedOperatorInstance = null;
    },
    setSelectedUserRole: (state, action) => {
      state.selectedUserRole = action.payload;
    },
    clearSelectedUserRole: (state) => {
      state.selectedUserRole = null;
    },
    setOtherUserData: (state, action) => {
      state.otherUserData = action.payload
    },
    clearOtherUserData: (state) => {
      state.otherUserData = null
    }
  },
});
export const {
  setUserProfile,
  clearUserProfile,
  setMediaId,
  clearMediaId,
  setUserTeamOperatorsRoles,
  clearUserTeamOperatorsRoles,
  setUserOperatorsInstances,
  clearUserOperatorsInstances,
  setSelectedOperatorInstance,
  clearSelectedOperatorInstance,
  setSelectedUserRole,
  clearSelectedUserRole,
  setOtherUserData,
  clearOtherUserData
} = profileSlice.actions;

export default profileSlice.reducer;
