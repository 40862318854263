import { createSlice } from "@reduxjs/toolkit";

export const servicepageSlice = createSlice({
  name: "servicepage",
  initialState: {
    service: null,
    tasks: [],
    deletedTaskIds: [],
    nextPage: null,
    prevPage: null,
    count: null,
  },
  reducers: {
    setTasksOfServiceResult: (state, action) => {
      state.tasks = action.payload?.data;
      state.next = action.payload?.pagination?.next;
      state.previous = action.payload?.pagination?.previus;
      state.count = action.payload?.pagination?.count;
    },
    setService: (state, action) => {
      state.service = action.payload;
    },
    setTasks: (state, action) => {
      state.tasks = action.payload;
    },
    setAssignedOperators: (state, action) => {
      state.service.operators = action.payload;
    },
    clearAssignedOperators: (state) => {
      state.service.operators = [];
    },
    setAssignedOperatorsOfSpecificTask: (state, action) => {
      state.tasks = state.tasks.map((task) => {
        if (task.id === action.payload.id) {
          return {
            ...task,
            operators: action.payload.operators,
          };
        }
        return task;
      });
    },
    clearAssignedOperatorsOfSpecificTask: (state, action) => {
      state.tasks = state.tasks.map((task) => {
        if (task.id === action.payload.taskId) {
          return {
            ...task,
            operators: [],
          };
        }
        return task;
      });
    },
    clearAll: (state) => {
      state.service = null;
      state.tasks = [];
      state.deletedTaskIds = [];
      state.nextPage = null;
      state.prevPage = null;
      state.count = null;
    },
    addDeletedTaskIds: (state, action) => {
      state.deletedTaskIds = [...state.deletedTaskIds, action.payload];
    },
  },
});

export const {
  setTasksOfServiceResult,
  setService,
  setTasks,
  setAssignedOperators,
  clearAssignedOperators,
  setAssignedOperatorsOfSpecificTask,
  clearAssignedOperatorsOfSpecificTask,
  clearAll,
  addDeletedTaskIds,
} = servicepageSlice.actions;

export default servicepageSlice.reducer;
