import { createSlice } from "@reduxjs/toolkit";

export const statsSlice = createSlice({
  name: "stats",
  initialState: {
    tasksStats: [],
    subtasksStats: [],
    chosenStats: [],
  },
  reducers: {
    setStats: (state, action) => {
      state.tasksStats = action.payload.tasks;
      state.subtasksStats = action.payload.subtasks;
    },
    setChosenStats: (state, action) => {
      state.chosenStats = action.payload;
    },
    clearChosenStats: (state) => {
      state.chosenStats = [];
    },
  },
});

export const { setStats, setChosenStats, clearChosenStats } =
  statsSlice.actions;
export default statsSlice.reducer;
