import { styled, createTheme } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import Popper from "@mui/material/Popper";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";

// global theme
export const theme = createTheme({
  typography: {
    fontFamily: "Poppins, Arial, sans-serif",
  },
  palette: {
    primary: {
      main: "#247DFF",
      main_r: "36",
      main_g: "125",
      main_b: "255",
    },
    error: {
      main: "#FF0000",
    },
    text: {
      primary: "#000",
      secondary: "#fff",
      error: "#FF0000",
    },
    background: { default: "#fff" }, // modify this for the background color
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        h3: {
          fontSize: "40px",
          fontWeight: "600",
          fontFamily: "Poppins",
          color: "#000",
          textAlign: "left",
        },
        h4: {
          fontSize: "24px",
          fontWeight: "600",
          fontFamily: "Poppins",
          color: "#000",
          textAlign: "left",
        },
        overline: {
          color: "#000",
          fontFamily: "Poppins, Arial, sans-serif",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: "500",
          wordWrap: "break-word",
          textTransform: "capitalize",
        },
        body1: {
          color: "#000",
          fontFamily: "Poppins, Arial, sans-serif",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: "500",
          wordWrap: "break-word",
        },
        body2: {
          color: "#000",
          fontSize: "16px",
          fontWeight: "500",
        },
        subtitle1: {
          fontFamily: "Poppins, Arial, sans-serif",
          fontSize: "13px",
          fontWeight: "400",
        },
        subtitle2: {
          fontFamily: "Poppins, Arial, sans-serif",
          fontSize: "12px",
          fontWeight: "400",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "capitalize",
          fontSize: 16,
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        row: {
          border: "none !important",
          color: "#424242",
        },
        cell: {
          border: "none !important",

          "& .MuiInputBase-root": {
            padding: "5px",
            "& fieldset": {
              borderColor: "#fff",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#247DFF",
            },
          },
          "& .MuiInputBase-input": {
            background: "#FFF",
            padding: "5px",
            "& fieldset": {
              borderColor: "#fff",
            },
          },

          "&:focus-within": {
            // This selector targets the cell itself when it's focused
            outline: "none !important",
          },
          "& .MuiDataGrid-cell:focus": {
            // This selector targets the inner cell element directly
            outline: "none !important",
          },
        },
        columnHeader: {
          "& .MuiDataGrid-columnHeaderTitle": {
            fontSize: "16px", // Set font size for column header titles
          },
        },
      },
    },
    MuiDatePicker: {
      styleOverrides: {
        root: {
          backgroundColor: "#fff",
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          background: "#fff",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            padding: "8px",
            "& fieldset": {
              border: "none",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#247DFF",
            },
            "&.Mui-disabled fieldset": {
              border: "none",
            },
          },
          "& .MuiOutlinedInput-input": {
            padding: "8px",
          },
          "& .MuiInputLabel-root": {
            color: "#000",
            "&.Mui-focused": {
              color: "#000",
            },
          },
        },
      },
    },
  },
});

export const textFieldStyle = {
  "& .MuiOutlinedInput-root": {
    background: "#F2F2F2",
    borderRadius: 10,
  },
  "& fieldset": {
    border: "none",
  },
  "& .MuiOutlinedInput-input": {
    paddingX: "16px",
  },
  "& .MuiAutocomplete-inputRoot": {
    paddingX: "15px",
  },
  "& .MuiInputBase-inputMultiline": {
    paddingX: "16px",
  },
  "& .MuiInputLabel-outlined, & .MuiInputRootLabel-outlined": {
    transform: "translate(22px, 16px) scale(1)",
  },
  "& .MuiInputLabel-shrink": {
    transform: "translate(22px, -10px) scale(0.75)",
  },
  background: "#F2F2F2",
  "& .MuiInputBase-input.Mui-disabled": {
    WebkitTextFillColor: "#000000",
  },
};

// textfield style for login and signup
export const textFieldStyleLoginSignup = {
  "& .MuiOutlinedInput-input": {
    background: "#F2F2F2",
    borderRadius: "30px !important",
  },
  "& fieldset": {
    border: "none",
  },
  background: "#F2F2F2",
  borderRadius: 20,
};

// search style for search textfield and select
export const searchStyle = {
  bgcolor: "#f2f2f2 !important",
  borderRadius: "50px",
  "& .MuiOutlinedInput-root": {
    padding: "0px",
    borderRadius: "50px",
    "&.Mui-focused fieldset": {
      borderColor: "transparent",
    },
    "&:hover fieldset": {
      borderColor: "black",
    },
    "& fieldset": {
      borderColor: "#bababa",
    },
  },
  "& .MuiOutlinedInput-input": {
    padding: "8px 14px",
    "&::placeholder": {
      color: "rgba(0, 0, 0)",
    },
  },
  "& .MuiSelect-outlined": {
    border: "none",
  },
  "& .MuiFormLabel-root": {
    color: "#247DFF",
  },
  "& .MuiInputLabel-root": {
    color: "#000",
    "&.Mui-focused": {
      color: "#000",
    },
  },
};

export const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  height: "auto",
  maxHeight: "80%",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 5,
  p: "40px 50px",
  display: "flex",
  alignItems: "flex-start",
  flexDirection: "column",
  gap: "20px",
};

export const buttonStyle = {
  display: "flex",
  paddingX: "35px",
  borderRadius: "35px",
  backgroundColor: "#f3f3f3",
  fontSize: "16px",
  color: "#000",
  borderColor: "#bababa",
  "&:hover": { borderColor: "black", backgroundColor: "#f3f3f3" },
};

// ios switch
export const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 38,
  height: 22,
  padding: 0,
  marginRight: 10,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#247DFF",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 18,
    height: 18,
  },
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

export const blueButtonStyle = {
  bgcolor: "#247DFF",
  color: "#FFFFFF",
  padding: "8px 22px",
};

export const redButtonStyle = {
  bgcolor: "#FEE1DF",
  color: "#FF0000",
  padding: "8px 22px",
  "&:hover": { bgcolor: "#FF889977" },
};

export const PopperStyledComponent = styled(Popper)(({ theme }) => ({
  border: `1px solid ${
    theme.palette.mode === "light" ? "rgba(149, 157, 165, 0.2)" : "rgb(1, 4, 9)"
  }`,
}));

export const numberInputStyle = {
  "& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button": {
    WebkitAppearance: "none",
    margin: 0,
  },
  "& input[type=number]": {
    MozAppearance: "textfield",
  },
};

export const StyledTabs = styled(Tabs)({
  minHeight: "48px",
  "& .MuiTabs-indicator": {
    backgroundColor: "black",
    height: "3px",
  },
});

export const StyledTab = styled(Tab)({
  textTransform: "none",
  minWidth: 72,
  fontWeight: 600,
  marginRight: "1rem",
  fontSize: "20px",
  color: "#000",
  "&.Mui-selected": {
    color: "black",
    fontSize: "25px",
  },
  "&.MuiTab-root": {
    minHeight: "48px",
  },
});

export const CustomInput = styled(TextField)(({}) => ({
  "& .MuiInputBase-root": {
    height: "100%",
    width: "100%",
    backgroundColor: "transparent !important",
    border: "none",
    padding: 0,
    margin: 0,
    "&:hover": {
      backgroundColor: "transparent",
    },
    "&.Mui-focused": {
      backgroundColor: "transparent",
    },
  },
  "& .MuiInputBase-input": {
    backgroundColor: "transparent !important",
    fontWeight: 600,
    color: "inherit",
    padding: "8px",
    height: "100%",
    "&::placeholder": {
      color: "rgba(0,0,0,0.6)",
      backgroundColor: "transparent",
    },
    "&:focus": {
      backgroundColor: "transparent",
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
    backgroundColor: "transparent",
  },
  backgroundColor: "transparent",
  height: "100%",
}));

// PLAIN SELECT STYLE

export const PlainSelect = styled(Select)({
  "& .MuiSelect-select": {
    display: "flex",
    alignItems: "center",
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 16,
    paddingBottom: 0,
  },
  "& .MuiSelect-icon": {
    display: "none",
  },
  "&.MuiOutlinedInput-root": {
    "& fieldset": {
      border: "none",
    },
  },
});

// Black rounded button

export const BlackRoundedButton = styled(Button)(({}) => ({
  backgroundColor: "black",
  color: "white",
  padding: "6px 22px",
  borderRadius: "30px",
  "&:hover": {
    backgroundColor: "black",
  },
}));

export const TextfieldBorderGray = styled(TextField)(({}) => ({
  width: "100%",
  "&.focused": {
    bgcolor: "#f1f7ff",
    border: "2px solid #d0e4ff",
  },
  border: "2px solid #F4F4F4",
  borderRadius: "5px",
}));
