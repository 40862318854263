import { createSlice } from "@reduxjs/toolkit";

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState: {
    notificationsRead: [],
    notificationsUnread: [],
    notificationsNotReadCount: null,
  },
  reducers: {
    setNotificationsRead: (state, action) => {
      state.notificationsRead = action.payload;
    },
    clearNotificationsRead: (state) => {
      state.notificationsRead = [];
    },
    setNotificationsUnread: (state, action) => {
      state.notificationsUnread = action.payload;
    },
    clearNotificationsUnread: (state) => {
      state.notificationsUnread = [];
    },
    setNotificationsNotReadCount: (state, action) => {
      state.notificationsNotReadCount = action.payload;
    },
    clearNotificationsNotReadCount: (state) => {
      state.notificationsNotReadCount = 0;
    },
  },
});

export const {
  setNotificationsRead,
  clearNotificationsRead,
  setNotificationsUnread,
  clearNotificationsUnread,
  setNotificationsNotReadCount,
  clearNotificationsNotReadCount,
} = notificationsSlice.actions;

export default notificationsSlice.reducer;
