import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import AnimationWrapper from "./AnimationWrapper";

export default function PublicRoute({ children }) {
  const navigate = useNavigate();
  const location = useLocation();

  const isCompleteProfile = location.pathname.startsWith(
    "/complete-registration"
  );

  React.useEffect(() => {
    if (
      localStorage.getItem("access") &&
      localStorage.getItem("refresh") &&
      !isCompleteProfile  // TODO: Remove this condition and handle it in a better way
    ) {
      navigate("/dashboard");
    }
  }, []);

  return <AnimationWrapper>{children}</AnimationWrapper>;
}
