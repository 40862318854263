import { createSlice } from "@reduxjs/toolkit";

export const snackbarSlice = createSlice({
  name: "snackbar",
  initialState: {
    open: false,
    message: "",
    severity: "",
  },
  reducers: {
    handleSnackbarClose: (state, action) => {
      if (action.payload.reason === "clickaway") {
        state.open = false;
        return state;
      }
      state.open = false;
    },
    setSnackbar: (state, action) => {
      state.open = action.payload.open ? action.payload.open : state.open;
      state.message = action.payload.message
        ? action.payload.message
        : state.message;
      state.severity = action.payload.severity
        ? action.payload.severity
        : state.severity;
      state.duration =
        action.payload.severity === "error"
          ? 6000 // 6 seconds
          : 3000; // 2 seconds;
    },
    setError: (state, action) => {
      state.open = true;
      state.message = action.payload.response?.data?.data?.error;
      state.severity = "error";
    },
  },
});

export const { handleSnackbarClose, setSnackbar, setError } =
  snackbarSlice.actions;

export default snackbarSlice.reducer;
