import { createSlice } from "@reduxjs/toolkit";

export const taskboardSlice = createSlice({
  name: "taskboard",
  initialState: {
    services: [],
    selectedService: null,
    subtasks: [],
    selectedSubTask: null,
  },
  reducers: {
    setServices: (state, action) => {
      state.services = action.payload;
    },
    setSelectedService: (state, action) => {
      state.selectedService = action.payload;
    },
    clearSelectedService: (state) => {
      state.selectedService = null;
    },
    clearServices: (state) => {
      state.services = [];
    },
    setSubTasks: (state, action) => {
      state.subtasks = action.payload;
    },
    clearSubTasks: (state) => {
      state.subtasks = [];
    },
    setSelectedSubTask: (state, action) => {
      state.selectedSubTask = action.payload;
    },
    clearSelectedSubTask: (state) => {
      state.selectedSubTask = null;
    },
  },
});

export const {
  setServices,
  setSelectedService,
  clearSelectedService,
  clearServices,
  setSubTasks,
  clearSubTasks,
  setSelectedSubTask,
  clearSelectedSubTask,
} = taskboardSlice.actions;

export default taskboardSlice.reducer;
