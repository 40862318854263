import { createSlice } from "@reduxjs/toolkit";

export const commentsSlice = createSlice({
  name: "comments",
  initialState: {
    comments: [],
    contentHandlers: [],
    selectedHandler: null,
    isCommentEditing: false,
  },
  reducers: {
    setComments: (state, action) => {
      state.comments = action.payload;
    },
    setContentHandlers: (state, action) => {
      state.contentHandlers = action.payload;
    },
    setSelectedHandler: (state, action) => {
      state.selectedHandler = action.payload;
    },
    clearSelectedHandler: (state) => {
      state.selectedHandler = null;
    },
    clearComments: (state) => {
      state.comments = [];
    },
    setIsCommentEditing: (state) => {
      state.isCommentEditing = true;
    },
    clearIsCommentEditing: (state) => {
      state.isCommentEditing = false;
    },
    clearAll: (state) => {
      state.comments = [];
      state.contentHandlers = [];
      state.selectedHandler = null;
      state.isCommentEditing = null;
    },
  },
});

export const {
  setComments,
  clearComments,
  setSelectedHandler,
  clearSelectedHandler,
  setContentHandlers,
  setIsCommentEditing,
  clearIsCommentEditing,
  clearAll,
} = commentsSlice.actions;
export default commentsSlice.reducer;
