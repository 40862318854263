import { createSlice } from "@reduxjs/toolkit";

export const mySubtaskSlice = createSlice({
  name: "mySubtask",
  initialState: {
    subtasks: [],
    next: null,
    previous: null,
    count: null,
  },
  reducers: {
    setSubtasksResult: (state, action) => {
      state.subtasks = action.payload.data;
      state.next = action.payload.pagination.next;
      state.previous = action.payload.pagination.previus;
      state.count = action.payload.pagination.count;
    },
    setSubtasks: (state, action) => {
      state.subtasks = action.payload;
    },
    clearSubtasks: (state) => {
      state.subtasks = [];
    },
    clearAll: (state) => {
      state.subtasks = [];
      state.next = null;
      state.previous = null;
      state.count = null;
    },
  },
});

export const {
  setSubtasksResult,
  setSubtasks,
  clearSubtasks,
  clearAll,
} = mySubtaskSlice.actions;

export default mySubtaskSlice.reducer;
