import { createSlice } from "@reduxjs/toolkit";

export const userAuthSlice = createSlice({
  name: "userAuth",
  initialState: {
    refresh: null,
    access: null,
    userType: null,
    operatorId: null,
    subdomain: null,
    isAuth: false,
  },
  reducers: {
    setLoginResponse: (state, action) => {
      state.refresh = action.payload.refresh;
      state.access = action.payload.access;
      state.userType = action.payload.user_type;
      state.operatorId = action.payload.operator_id;
      state.subdomain = action.payload.subdomain;
    },
    setRefresh: (state, action) => {
      state.refresh = action.payload;
    },
    setAccess: (state, action) => {
      state.access = action.payload;
    },
    setUserType: (state, action) => {
      state.userType = action.payload;
    },
    setOperatorId: (state, action) => {
      state.operatorId = action.payload;
    },
    setSubdomain: (state, action) => {
      state.subdomain = action.payload;
    },
    setIsAuth: (state) => {
      state.isAuth = true;
    },
    clearRefresh: (state) => {
      state.refresh = null;
    },
    clearAccess: (state) => {
      state.access = null;
    },
    clearUserType: (state) => {
      state.userType = null;
    },
    clearOperatorId: (state) => {
      state.operatorId = null;
    },
    clearSubdomain: (state) => {
      state.subdomain = null;
    },
    clearIsAuth: (state) => {
      state.isAuth = false;
    },
  },
});

export const {
  setLoginResponse,
  setRefresh,
  setAccess,
  setUserType,
  setSubdomain,
  setIsAuth,
  setOperatorId,
  clearRefresh,
  clearAccess,
  clearUserType,
  clearSubdomain,
  clearOperatorId,
  clearIsAuth,
} = userAuthSlice.actions;

export default userAuthSlice.reducer;
