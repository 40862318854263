import React from 'react'
import { motion } from "framer-motion";

export default function AnimactionWrapper({ children }) {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      style={{
        height: "100%",
        overflowY: "auto",
      }}
    >
      {children}
    </motion.div>
  );
}
